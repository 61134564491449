import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  GetCompanyIndustries,
  GetCompanySizes,
  GetFunctionRoles,
  GetSeniorityLevels
} from '../actions/dashboard.actions';
import {DashboardService} from '../../_services/dashboard.service';

export interface DashboardStateModel {
  companyIndustries: string[];
  companySizes: any;
  functionRoles: string[];
  seniorityLevels: string[];
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    companyIndustries: [],
    companySizes: [],
    functionRoles: [],
    seniorityLevels: []
  },
})

@Injectable()

export class DashboardState {
  constructor(private dashboardService: DashboardService) {
  }

  @Selector()
  static getCompanyIndustries(state: DashboardStateModel) {
    return state.companyIndustries;
  }

  @Selector()
  static getCompanySizes(state: DashboardStateModel) {
    return state.companySizes;
  }

  @Selector()
  static getFunctionRoles(state: DashboardStateModel) {
    return state.functionRoles;
  }

  @Selector()
  static getSeniorityLevels(state: DashboardStateModel) {
    return state.seniorityLevels;
  }

  @Action(GetCompanyIndustries)
  getCompanyIndustries({getState, setState}: StateContext<DashboardStateModel>) {
    this.dashboardService.getCompanyIndustries().subscribe((companyIndustries: string[]) =>
      setState({
        ...getState(),
        companyIndustries
      }));
  }

  @Action(GetCompanySizes)
  getCompanySizes({getState, setState}: StateContext<DashboardStateModel>) {
    this.dashboardService.getCompanySizes().subscribe((companySizes: any) =>
      setState({
        ...getState(),
        companySizes
      }));
  }

  @Action(GetFunctionRoles)
  getFunctionRoles({getState, setState}: StateContext<DashboardStateModel>) {
    this.dashboardService.getFunctionRoles().subscribe((functionRoles: string[]) =>
      setState({
        ...getState(),
        functionRoles
      }));
  }

  @Action(GetSeniorityLevels)
  getSeniorityLevels({getState, setState}: StateContext<DashboardStateModel>) {
    this.dashboardService.getSeniorityLevels().subscribe((seniorityLevels: string[]) =>
      setState({
        ...getState(),
        seniorityLevels
      }));
  }
}
