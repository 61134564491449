export class GetCompanyIndustries {
  static readonly type = '[CompanyIndustries] Get';
}

export class GetCompanySizes {
  static readonly type = '[CompanySizes] Get';
}

export class GetFunctionRoles {
  static readonly type = '[FunctionRoles] Get';
}

export class GetSeniorityLevels {
  static readonly type = '[SeniorityLevels] Get';
}
