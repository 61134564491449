import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable()

export class DashboardService {
  constructor(private httpClient: HttpClient) {
  }

  getCompanyIndustries() {
    return this.httpClient.get(`${environment.apiUrl}/company-industries`);
  }

  getCompanySizes() {
    return this.httpClient.get(`${environment.apiUrl}/company-sizes`);
  }

  getFunctionRoles() {
    return this.httpClient.get(`${environment.apiUrl}/functions-roles`);
  }

  getSeniorityLevels() {
    return this.httpClient.get(`${environment.apiUrl}/seniority-levels`);
  }
}
